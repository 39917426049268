<template>
  <div class="QueryRegion">
    <div class="QueryRegion_h">
      <div class="QueryRegion_bac"></div>
      <div class="QueryRegion_til">
        <img src="@/assets/img/query/query_til.png" alt="" />
      </div>
      <div class="QueryRegion_box">
        <div class="QueryRegion_content">
          <van-field
            :value="valueCity"
            label="查询地区"
            placeholder="请选择"
            readonly
            clickable
            is-link
            name="picker"
            :border="false"
            input-align="right"
            @click="showPicker = true"
          />
        </div>
      </div>
      <van-button class="QueryRegion_btn" type="info" round @click="queryHandler">查询</van-button>
    </div>
    <!--选择地区-->
    <van-popup class="van-popup-custom" v-model="showPicker" position="bottom" round :style="{ height: '90%' }">
      <div class="popup-header">
        <div class="popup-title">
          <span class="popup-title-text">请选择地区</span>
          <van-icon name="cross" class="popup-header-close" @click="showPicker = false" />
        </div>
        <van-search v-model="keywords" :placeholder="`请输入${ showHotCity ? '城市' : '地区' }名称`" maxlength="30" />
      </div>
      <div class="popup-content">
        <template v-if="!keywords">
          <div class="hot-region" v-if="showHotCity && hotCityList.length">
            <div class="title">热门地区</div>
            <div class="hot-region-list">
              <div class="hot-region-item" v-for="item in hotCityList" :key="item" @click="onConfirm(item)">{{ item }}</div>
            </div>
          </div>
          <van-index-bar :index-list="indexList" :sticky="false">
            <template v-for="item in cityList">
              <van-index-anchor :index="item.label" />
              <van-cell v-for="cityItem in item.options" :title="cityItem.city" @click="onConfirm(cityItem.city)" />
            </template>
          </van-index-bar>
        </template>
        <template v-else-if="cityListFilter.length > 0">
          <van-cell v-for="cityItem in cityListFilter" :title="cityItem.city" @click="onConfirm(cityItem.city)" />
        </template>
        <template v-else>
          <div class="empty">无搜索结果，请尝试修改后重试</div>
        </template>
      </div>
    </van-popup>
    <copyrightIp></copyrightIp>
    <!-- 查询超限提示 -->
    <PopupTips :show.sync="showLimitQuery">
      <p>
        您今日或本月查询次数超限，如需相关服务，请咨询公司的<span style="color: #1789f9">客服专员</span>或在公众号<span style="color: #1789f9">回复【合作】</span>留言
      </p>
    </PopupTips>
  </div>
</template>

<script>
import { getCityList, getSalaryResult, postLog } from "@/api/salaryQuery";
import { validatenull } from "@/utils/validate";
import { Notify, Field, Picker, Popup, Button, IndexBar,IndexAnchor, Cell, Search, Icon } from "vant";
import copyrightIp from "@/components/copyrightIp";
import PopupTips from "./components/PopupTips.vue";
import resultData2Content from './js/resultData2Content'

export default {
  components: {
    [Notify.name]: Notify,
    [Field.name]: Field,
    [Picker.name]: Picker,
    [Popup.name]: Popup,
    [Button.name]: Button,
    [IndexBar.name]: IndexBar,
    [IndexAnchor.name]: IndexAnchor,
    [Cell.name]: Cell,
    [Search.name]: Search,
    [Icon.name]: Icon,
    copyrightIp,
    PopupTips,
  },
  data() {
    return {
      keywords: '',
      valueCity: "",
      indexList: [],
      cityList: [],
      hotCityList: [],
      showPicker: false,
      showLimitQuery: false
    };
  },
  beforeCreate() {
    const queryType = this.$route.params.queryType;
    this.queryType = queryType;
    const map = {
      averageSalary: "平均工资查询",
      corporatePension: "企业年金查询",
      dimissionAllowance: "经济补偿查询",
      housingFund: "住房公积金查询",
      minimumWage: "最低工资查询",
      socialSecurityPayment: "社保缴纳查询",
      disabledExemption: '残疾人免税政策查询',
      continuingEducation: '继续教育查询',
      housingRent: '住房租金查询',
      maternityLeave: '婚假产假查询',
      childCare: '子女护理假查询',
      highTemperatureAllowance: '高温津贴查询',
      transportationCommunication: '交通、通讯补贴查询',
    };
    document.title = map[queryType];
  },
  created() {
     // localStorage.setItem( 'querSalaryUserId' , Math.random().toString(36).substring(2, 10));
    this.fetcCityList();
  },
  methods: {
    // 确认选择地区
    onConfirm(value) {
      this.showPicker = false;
      this.valueCity = value;
    },
    // 获取地区列表
    fetcCityList() {
      const { queryCodeId } = this.$route.params;
      getCityList({ codeItemId: queryCodeId }).then((res) => {
        if (res.data.code == 0) {
          const { city, hotCity } = res.data.data;
          this.cityList = city;
          this.hotCityList = hotCity;
          this.indexList = city.map(item => item.label);
        } else {
          Notify("获取地区列表失败");
        }
      });
    },
    // 查询
    async queryHandler() {
      if (!this.valueCity) {
        return Notify("请选择查询地区");
      }
      try {
        const { queryCodeId, queryType } = this.$route.params;
        const res = await getSalaryResult({
          userId: localStorage.getItem("querSalaryUserId"),
          queryFunction: queryCodeId,
          scope: this.valueCity,
          year: new Date().getFullYear(),
        });

        if (res.data.code == 0) {
          // const resData = queryType == "dimissionAllowance" ? { dimissionAllowanceList: res.data.data } : res.data.data;
          const resData = res.data.data;
          const result = {
            ...resData,
            city: this.valueCity,
            year: new Date().getFullYear(),
          };
          this.querySuccess(result);
          const resultData = resultData2Content(resData, queryType);
          const logValue = validatenull(resultData) ? this.valueCity : resultData;
          this.postLog('查询成功: '+ logValue);
        } else {
          res.data.code == 1 ? this.showLimitQuery = true : Notify(res.data.msg)
          this.postLog('查询失败: '+ res.data.msg)
        }
      } catch (error) {
        this.postLog('查询失败: 未知错误');
      }
    },
    // 跳转到查询结果页
    querySuccess(result) {
      sessionStorage.setItem("querSalaryResult", JSON.stringify(result));
      sessionStorage.setItem("isShare", false);
      const { queryCodeId, queryType } = this.$route.params;
      this.$router.push({
        path: `/salaryQueryv1/result/${queryCodeId}/${queryType}/${this.valueCity}`,
      });
    },
    // 埋点
    postLog(res) {
      const map = {
        corporatePension: "企业年金",
        dimissionAllowance: "经济补偿",
        housingFund: "住房公积金",
        disabledExemption: '残疾人',
        continuingEducation: '继续教育',
        housingRent: '住房租金',
        averageSalary: "平均工资",
        socialSecurityPayment: "社保缴纳",
        minimumWage: "最低工资",
        maternityLeave: '婚假产假',
        childCare: '子女护理假',
        highTemperatureAllowance: '高温津贴',
        transportationCommunication: '交通、通讯补贴'
      }
      postLog({
        userId: localStorage.getItem("querSalaryUserId"),
        clickPlace: "查询-" + map[this.$route.params.queryType],
        queryResult: res,
      });
    }
  },
  computed: {
    cityListFilter() {
      const keywords = this.keywords;
      const cityListOri = this.cityList;
      let cityListFilter = [];
      cityListOri.forEach(item => {
        item.options.forEach(cityItem => {
          if (cityItem.city.includes(keywords) || cityItem.pinyin.includes(keywords)) {
            cityListFilter = [...cityListFilter, cityItem];
          }
        })
      })
      return cityListFilter;
    },
    // 是否显示热门地区 婚假产假、子女护理假、高温补贴、交通通讯补贴取消热门地区
    showHotCity() {
      const queryType = this.$route.params.queryType;
      const list = ['maternityLeave', 'childCare', 'highTemperatureAllowance', 'transportationCommunication'];
      return list.indexOf(queryType) == -1;
    }
  }
};
</script>

<style lang="scss" scoped>
.QueryRegion {
  width: 100%;
  height: 100%;
  position: relative;
  .QueryRegion_h {
    min-height: calc(100% - 74px);
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    border: 1px solid rgba(0,0,0,0);
  }
  .QueryRegion_bac {
    width: 100%;
    height: 130px;
    background-image: url("../../assets/img/query/bg2.png");
    background-size: 100%;
    background-repeat: no-repeat;
    background-position-y: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }
  .QueryRegion_til {
    width: 300px;
    padding: 32px 0;
    margin: auto;
    img {
      text-align: center;
      width: 100%;
    }
  }
  .QueryRegion_box {
    width: 100%;
    padding: 0 10px;
    .QueryRegion_content {
      width: 100%;
      height: 50px;
      background: #fff;
      border-radius: 10px;
      overflow: hidden;
      display: flex;
      align-items: center;
    }
  }
  .QueryRegion_btn {
    display: block;
    margin: 50px auto;
    width: 350px;
    height: 50px;
    background: linear-gradient(90deg, #4881f5, #5bacfc);
    border-radius: 25px;
    font-size: 16px;
    font-weight: 500;
  }
  .van-popup-custom {
    .popup-header {
      /*background-color: #52B71C;*/
      padding: 0 15px;
      height: 100px;
      .popup-title {
        height: 55px;
        line-height: 55px;
        text-align: center;
        position: relative;
        .popup-title-text {
          font-size: 16px;
        }
        .popup-header-close {
          font-size: 20px;
          padding: 5px;
          position: absolute;
          right: 0;
          top: 50%;
          -webkit-transform: translateY(-50%);
          -moz-transform: translateY(-50%);
          -ms-transform: translateY(-50%);
          -o-transform: translateY(-50%);
          transform: translateY(-50%);
        }
      }
      ::v-deep .van-search {
        padding: 0;
        &:focus {
          border: 1px solid #409CF5;
        }
        .van-cell {
          line-height: 32px;
        }
        .van-search__content {
          -webkit-border-radius: 5px;
          -moz-border-radius: 5px;
          border-radius: 5px;
        }
      }
    }
    .popup-content {
      padding: 0 15px;
      height: calc(100% - 110px);
      overflow: auto;
      ::v-deep .van-cell {
        padding: 10px 0;
        font-size: 12px;
        &::after {
          left: 0;
        }
      }
      ::v-deep .van-index-anchor {
        padding: 0;
        color: #999;
        font-size: 14px;
      }
      ::v-deep .van-index-bar__sidebar {
        position: absolute;
        .van-index-bar__index {
          font-size: 11px;
          line-height: 18px;
          color: #409CF5;
        }
        .van-index-bar__index--active {
          color: #ee0a24;
        }
      }
      .hot-region {
        .title {
          line-height: 20px;
          font-size: 14px;
          color: #999;
          padding: 10px 0;
        }
        .hot-region-list {
          display: flex;
          flex-wrap: wrap;
          .hot-region-item {
            width: calc(25% - 7px);
            height: 34px;
            display: flex;
            align-items: center;
            justify-content: center;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
            background-color: #F5F5F5;
            -webkit-border-radius: 5px;
            -moz-border-radius: 5px;
            border-radius: 5px;
            font-size: 12px;
            margin: 0 7px 7px 0;
            &:nth-child(4n){
              margin-right: 0;
            }
          }
        }
      }
      .empty {
        color: #999999;
        font-size: 12px;
        margin-top: 15px;
        text-align: center;
      }
    }
  }
}
</style>