<template>
  <van-popup
    class="PopupTips"
    :value="show"
    :close-on-click-overlay="false"
    overlay-class="PopupTips_overlay"
  >
    <h3 class="PopupTips_title">{{title}}</h3>
    <div class="PopupTips_cont">
      <slot></slot>
    </div>
    <img @click="onClose" class="PopupTips_closeBtn" src="@/assets/img/query/close.png" alt="">
  </van-popup>
</template>

<script>
import { Popup } from "vant";
export default {
  components:{
    [Popup.name]: Popup,
  },
  props: {
    show: Boolean,
    title: {
      type: String,
      default: '温馨提示'
    }
  },
  methods: {
    onClose() {
      this.$emit("update:show", false);
      this.$emit('onClose')
    }
  }
}
</script>

<style lang="scss">
.PopupTips {
  top: 35%;
  width: 345px;
  max-height: 60%;
  border-radius: 10px;
  background: #FFF;
  padding: 18px 0;
  overflow: visible;
  .PopupTips_title {
    font-size: 18px;
    font-weight: bold;
    color: #333;
    line-height: 1;
    text-align: center;
  }
  .PopupTips_cont {
    font-size: 14px;
    line-height: 24px;
    color: #666;
    padding: 20px 18px 20px;
  }
  .PopupTips_closeBtn {
    position: absolute;
    bottom: -56px;
    left: calc(50% - 14px);
    width: 28px;
    height: 28px;
  }
}
.PopupTips_overlay {
  background-color: rgba(0, 0, 0, 0.5)
}
</style>