/**
 * 判断是否为空
 */
export function validatenull(val) {
  if (val === 'null' || val === null || val === 'undefined' || val === undefined || val === '') {
    return true;
  } else if (val instanceof Array) {
    if (val.length == 0){
      return true;
    }
    return false;
  } else if (val instanceof Object) {
    if (JSON.stringify(val) === '{}'){
      return true;
    }
    return false;
  }
  return false;
}